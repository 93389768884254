import { SET_USER, CLEAR_USER, SET_USER_PAY } from "./userActions";

// Helper functions for setting and getting cookies
const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? decodeURIComponent(match[2]) : null;
};

let initialState = {
  user: null,
  token: null,
};

// Check if user info and token are stored in cookies
const cookieValue = getCookie("userInfo");
const tokenValue = getCookie("token");

if (cookieValue) {
  try {
    const parsedUser = JSON.parse(cookieValue);
    initialState.user = parsedUser || null;
  } catch (error) {
    // console.error("Failed to parse user from cookie:", error);
  }
}

if (tokenValue) {
  initialState.token = tokenValue;
}

// User reducer to handle login, logout, and update user state
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      const { user, token } = action.payload;
      setCookie("userInfo", JSON.stringify(user), 3650); // Save user data in cookies for 10 years
      setCookie("token", token, 3650); // Save token in cookies for 10 years
      return { ...state, user, token }; // Store user and token in Redux

    case CLEAR_USER:
      setCookie("userInfo", "", -1); // Clear user cookie by setting expiration in the past
      setCookie("token", "", -1); // Clear token cookie
      return { ...state, user: null, token: null }; // Clear user and token state in Redux

    case SET_USER_PAY:
      const { formData } = action.payload;

      // Update user state with new data
      const updatedUser = {
        ...state.user, // Spread existing user data
        name: formData.name,
        state: formData.state,
        district: formData.district,
        contactNo: formData.contactNo,
        pincode: formData.pincode,
        address: formData.address,
      };

      setCookie("userInfo", JSON.stringify(updatedUser), 3650); // Save updated user data in cookies

      return {
        ...state,
        user: updatedUser, // Update the user in the Redux store
      };

    default:
      return state;
  }
};

export default userReducer;
