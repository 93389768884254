import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentItem from "./PaymentItem";
import { clearUser } from "../Redux/userActions";

const UserProfile = ({handleClick}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return; // Prevent further execution if user is null
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const fetchPaymentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              email: user.email,
            },
          }
        );
        setPaymentData(response.data);
      } catch (error) {
        // Handle error if needed
        // console.error("Error fetching payment data:", error);
      }
    };

    fetchPaymentData();
    handleClick('User', 'Checking Dashboard', 'Dashboard', 1);
  }, [user, token, navigate,handleClick]);

  const logout = () => {
    dispatch(clearUser());
    navigate("/");
  };

  // Conditional rendering to prevent access to properties of null
  if (!user) {
    return null; // Or a loading spinner, etc.
  }

  return (
    <div className="p-4 bg-gray-100 mt-32 md:mt-24 rounded shadow-lg max-w-md mx-auto">
      <div className="flex justify-between items-center w-full">
        <h2 className="text-xl font-semibold mb-2">User Profile</h2>
        <button
          onClick={logout}
          className="p-3 px-8 text-white bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
        >
          Logout
        </button>
      </div>
      <p>
        <strong>Name:</strong> {user.name}
      </p>
      <p>
        <strong>Email:</strong> {user.email}
      </p>
      <p>
        <strong>Pincode:</strong> {user.pincode}
      </p>
      <p>
        <strong>State:</strong> {user.state}
      </p>
      <p>
        <strong>District:</strong> {user.district}
      </p>
      <p>
        <strong>Full Address:</strong> {user.address}
      </p>
      <p>
        <strong>Contact No:</strong> {user.contactNo}
      </p>

      <div className="p-4 bg-gray-100 mt-12 rounded shadow-lg max-w-md mx-auto">
        <h2 className="text-lg font-semibold mb-2">Your Payments</h2>
        {paymentData.length > 0 ? (
          paymentData.map((payment) => (
            <PaymentItem key={payment.id} payment={payment} />
          ))
        ) : (
          <p>No payment data available.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
