import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { loginWithGoogle, setUser } from "../Redux/userActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input"; // Make sure to install this package
// import { BiArrowBack } from "react-icons/bi";
import { RxCountdownTimer } from "react-icons/rx";
// import Phone from "./Phone";
const LoginPage = ({handleClick}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(""); // Updated to be a string for OTP
  const navigate = useNavigate();
  const [insertOtp, setInsertOtp] = useState(false);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [user, navigate]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    handleClick('User', 'Submitted Login Form', 'Login Form', 1);

    try {
      toast.loading("Please Wait ...");
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/sendotp`,
        { email }
      );

      if (response.data.success) {
        setInsertOtp(true);
        toast.success("OTP sent successfully!"); // Notify user
      } else {
        toast.error("Failed to send OTP."); // Notify if OTP sending fails
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login failed. Please try again.";
      toast.error(errorMessage); // Notify error
    } finally {
      toast.dismiss();
    }
  };

  const handleVerifyAndSignup = async (e) => {
    e.preventDefault();
    toast.loading("Please Wait ...");
    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/verifyemail`,
        { email, otp }
      );
      const { user, token } = otpResponse.data;
      dispatch(setUser(user, token));
      toast.dismiss();
      toast.success("Login successful!"); // Notify success
      setTimeout(() => {
        navigate("/cart");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Verification failed. Please try again.";
      // console.log(errorMessage);
      toast.dismiss();
      toast.error(errorMessage); // Notify error
    } finally {
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    handleClick('User', 'Submitted Login Form', 'Login Form', 1);

    try {
      const { credential } = credentialResponse;
      await dispatch(loginWithGoogle(credential));
      toast.success("Login Successful!");
      setTimeout(() => {
        navigate("/cart");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
    } catch (error) {
      // console.error("Google Login Error:");
      toast.error(
        "Google Login Failed: " +
          (error.response?.data?.message || "Unknown error")
      );
    }
  };
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <h1 className="text-3xl mb-4">Login</h1>
        <div className="w-80">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onFailure={(error) => {
              // console.error("Google Login Error:", error);
              toast.error("Failed to login with Google");
            }}
            style={{ width: "100%" }} // Ensure Google button fits
          />
        </div>
        <div className="my-4">or</div>

        <form
          onSubmit={handleEmailLogin}
          className="flex flex-col w-80 bg-white p-6 rounded shadow-md"
        >
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-2 p-2 border border-gray-300 rounded"
            required
          />
          <button
            type="submit"
            className="bg-orange-400 text-white p-2 rounded hover:bg-orange-500 transition"
          >
            Generate OTP
          </button>
        </form>

        {insertOtp && (
          <div className="sm:w-[450px] w-[350px] p-4 lg:p-8">
            <p className="text-[1.125rem] leading-[1.625rem] my-4 ">
              A verification code has been sent to you. Enter the code below
            </p>
            <form onSubmit={handleVerifyAndSignup}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px] lg:w-[60px] border-0 bg-richblack-800 rounded-[0.5rem] text-richblack-5 aspect-square text-center focus:border-0 focus:outline-2 focus:outline-yellow-50"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />
              <button
                type="submit"
                className="w-full bg-orange-400 py-[12px] px-[12px] rounded-[8px] mt-6 font-medium text-white"
              >
                Verify Email
              </button>
            </form>
            <div className="mt-6 flex items-center justify-between">
              <button
                className="flex items-center text-blue-500 gap-x-2"
                onClick={() => {
                  toast.loading("Resending OTP...");
                  axios
                    .post(
                      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/sendotp`,
                      { email }
                    )
                    .then(() => {
                      toast.success("OTP resent successfully!");
                    })
                    .catch(() => {
                      toast.error("Failed to resend OTP.");
                    })
                    .finally(() => {
                      toast.dismiss();
                    });
                }}
              >
                <RxCountdownTimer />
                Resend it
              </button>
            </div>
          </div>
        )}
        {/* <Phone></Phone> */}
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
