import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../Redux/cartActions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const Card = ({ image, title, netWeight, ite, priceQuantityData }) => {
  const prices = [111, 151, 251, 501, 551];
  const [selectedPrice, setSelectedPrice] = useState(prices[1]);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(ite.quantity);
  const [orderPlaced, setOrderPlaced] = useState(quantity > 0);

  const handleBuyP = (val) => {
    setSelectedPrice(val);
    const item = priceQuantityData.find(
      (it) => it.price === val && it.quantity > 0
    );
    setOrderPlaced(!!item);
  };

  const weight = () => {
    let wt = netWeight * selectedPrice;
    wt = Math.round((1000 * wt) / 100) * 100;
    // if (ite.quantity !== 0)   wt*=ite.quantity;
    return wt < 1000 ? `${wt} gm` : `${(wt / 1000).toFixed(2)} kg`;
  };

  const handleBuy = () => {
    if (!orderPlaced) {
      setQuantity(1);
      setOrderPlaced(true);
      dispatch(addToCart({ ...ite, quantity: 1, price: selectedPrice }));
    }
  };

  // const handleIncrease = () => {
  //   const newQuantity = quantity + 1;
  //   setQuantity(newQuantity);
  //   dispatch(
  //     addToCart({ ...ite, quantity: newQuantity, price: selectedPrice })
  //   );
  // };

  // const handleDecrease = () => {
  //   if (quantity > 1) {
  //     const newQuantity = quantity - 1;
  //     setQuantity(newQuantity);
  //     dispatch(
  //       addToCart({ ...ite, quantity: newQuantity, price: selectedPrice })
  //     );
  //   } else {
  //     setQuantity(0);
  //     setOrderPlaced(false);
  //     handleRemove(ite);
  //   }
  // };

  // const handleRemove = () => {
  //   dispatch(addToCart({ ...ite, quantity: 0, price: selectedPrice })); // Set quantity to 0 to remove
  // };

  return (
    <div className=" h-auto rounded-lg  overflow-hidden">
      <div className="">
        <img
          src={image}
          alt={title}
          className="w-full h-40 object-cover rounded-xl"
        />
      </div>
      <div className="">
        <h2 className="font-bold text-lg mb-2">{title}</h2>
        <div className="font-semibold">{weight()}</div>

        <div className="flex-row text-xs flex-wrap items-center md:gap-3 gap-1">
          <div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-3 text-sm h-auto items-center justify-center mr-4 mt-2">
            {prices.slice(0, 4).map((price, index) => {
              if (
                (index === 0 && price * netWeight >= 0.5) ||
                (index !== 0 && price * netWeight <= 1.7)
              ) {
                return (
                  <div
                    key={index}
                    className={`p-1 px-0 border-2 border-orange-500 w-auto text-center rounded-lg  cursor-pointer ${
                      selectedPrice === price
                        ? "bg-orange-500 text-white"
                        : "bg-white text-orange-500 hover:bg-orange-500 hover:text-white"
                    }`}
                    onClick={() => handleBuyP(price)}
                  >
                    ₹ {price}
                  </div>
                );
              }
              return null;
            })}
          </div>

          {/* {orderPlaced && (
            <div className="flex items-center mt-4 border-2 gap-4  border-green-500 justify-center rounded-xl">
              <button
                onClick={handleDecrease}
                className="mx-2 p-1 text-red-600"
                disabled={quantity === 0}
                aria-label="Decrease quantity"
              >
                <FontAwesomeIcon icon={faMinusCircle} size="2xl" />
              </button>
              <span className="text-xl">{quantity}</span>
              <button
                onClick={handleIncrease}
                className="mx-2 p-1 text-green-600"
                aria-label="Increase quantity"
              >
                <FontAwesomeIcon icon={faPlusCircle} size="2xl" />
              </button>
            </div>
          )} */}
          {orderPlaced && (
            <button
              // disabled
              className="w-full mt-4 text-sm py-2 bg-white border-2 border-green-500 rounded-lg  font-semibold text-green-500"
              aria-label="Add to cart"
            >
              Added to cart
            </button>
          )}

          {!orderPlaced && (
            <button
              onClick={handleBuy}
              className="w-full mt-4 text-sm py-2 bg-green-500 rounded-lg font-semibold text-white"
              aria-label="Add to cart"
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;

// import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { addToCart } from "../Redux/cartActions";
// import "./Card.css";
// import { toast } from "react-hot-toast";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// // import { useSelector } from "react-redux";

// const Card = ({ image, title, description, netWeight, ite }) => {
//   // const [customPrice, setCustomPrice] = useState("");
//   // const totalWei = useSelector((state) => state.cart.totalWeight || []);
//   const prices = [111, 151, 251, 501, 551];
//   const [selectedPrice, setSelectedPrice] = useState(prices[1]);
//   const dispatch = useDispatch();
//   const [quantity, setQuantity] = useState(ite.quantity);
//   const [orderPlaced, setOrderPlaced] = useState(quantity > 0);
//   // const [weight,setWeight]= useState(0);

//   // const handleIncrease = () => {
//   //   // if(totalWei + ite.selectedPrice)
//   //   setOrderPlaced(true);
//   //   setQuantity(quantity + 1);
//   //   ite.quantity = quantity + 1;
//   //   dispatch(
//   //     addToCart({ ...ite, quantity: quantity + 1, price: selectedPrice })
//   //   );
//   // };

//   // const handleDecrease = () => {
//   //   setQuantity(quantity - 1);
//   //   ite.quantity = quantity - 1;
//   //   if (ite.quantity > 0) {
//   //     dispatch(addToCart({ ...ite, price: selectedPrice }));
//   //   } else {
//   //     setOrderPlaced(false);
//   //     handleRemove(ite);
//   //   }
//   // };

//   // const handleRemove = () => {
//   //   dispatch(addToCart({ ...ite, price: selectedPrice }));
//   // };

//   // const handlePriceChange = (e) => {
//   //   let value = e.target.value;
//   //   setSelectedPrice(Number(value));
//   //   // setCustomPrice("");
//   //   if (orderPlaced) handleBuyP(Number(value));
//   // };

//   // const handleCustomPriceChange = (e) => {
//   //   const value = e.target.value;
//   //   setCustomPrice(Math.max(Number(value), 0));
//   //   setSelectedPrice(Number(value) > 0 ? Number(value) : prices[1]);

//   //   if (orderPlaced) handleBuyP(Number(value));
//   // };
//   const isMobile = window.innerWidth < 768;

//   const handleBuyP = (val) => {
//     // if (val < 51) {
//     //   setSelectedPrice(0);
//     //   toast("Minimum order : more than ₹50.", {
//     //     icon: "ⓧ",
//     //     style: {
//     //       borderRadius: "10px",
//     //       background: "#DC143C",
//     //       color: "#FFFFFF",
//     //       fontSize: isMobile ? "0.875rem" : "1rem",
//     //       padding: isMobile ? "1rem" : "1.5rem",
//     //       fontWeight: "bold",
//     //     },
//     //   });
//     // } else if (val > 1200) {
//     //   toast("Maximum order : less than ₹1200.", {
//     //     icon: "ⓧ",
//     //     style: {
//     //       margin: "1rem",
//     //       borderRadius: "10px",
//     //       background: "#DC143C",
//     //       color: "#FFFFFF",
//     //       fontSize: isMobile ? "0.875rem" : "1rem",
//     //       padding: isMobile ? "1rem" : "1.3rem",
//     //       fontWeight: "bold",
//     //     },
//     //   });
//     // } else {
//       setOrderPlaced(true);
//       setSelectedPrice(val)
//       dispatch(addToCart({ ...ite, price: val }));
//     // }
//   };
//   const weight = () => {
//     let wt = netWeight * selectedPrice * ite.quantity;
//     if (ite.quantity === 0) wt = netWeight * selectedPrice;
//     if (wt < 1) {
//       return `${(wt * 1000).toFixed(0)} gm`;
//     }
//     return `${wt.toFixed(2)} kg`;
//   };

//   const handleBuy = () => {
//     // if (selectedPrice < 51) {
//     //   toast("Minimum order : more than ₹50.", {
//     //     icon: "ⓧ",
//     //     style: {
//     //       borderRadius: "10px",
//     //       background: "#DC143C",
//     //       color: "#FFFFFF",
//     //       fontSize: isMobile ? "0.875rem" : "1rem",
//     //       padding: isMobile ? "1rem" : "1.5rem",
//     //       fontWeight: "bold",
//     //     },
//     //   });
//     // } else if (selectedPrice > 1200) {
//     //   toast("Maximum order : less than ₹1200.", {
//     //     icon: "ⓧ",
//     //     style: {
//     //       margin: "1rem",
//     //       borderRadius: "10px",
//     //       background: "#DC143C",
//     //       color: "#FFFFFF",
//     //       fontSize: isMobile ? "0.875rem" : "1rem",
//     //       padding: isMobile ? "1rem" : "1.3rem",
//     //       fontWeight: "bold",
//     //     },
//     //   });
//     // } else {
//       setQuantity(quantity + 1);
//       setOrderPlaced(true);
//       dispatch(
//         addToCart({ ...ite, quantity: quantity + 1, price: selectedPrice })
//       );
//     // }
//   };

//   return (
//     <div className="md:w-[25rem] h-auto md:h-[31.5rem] w-auto rounded-3xl">
//       <div className=" ">
//         <div className=" md:h-[31.5rem] ">
//           <img
//             src={image}
//             alt={title}
//             className="md:h-[31.5rem]  h-[10.5rem] object-cover rounded-xl"
//           />
//         </div>
//         <div className=" md:h-[6.94rem] md:w-[98.25%] mt-1  ">
//           <div className=" w-full md:mx-[1.2rem] ">
//             <div className="flex justify-between">
//               <div className=" font-bold md:text-2xl ">{title}</div>
//             </div>
//             {/* <div className=" w-full md:text-base text-sm ">
//               Content: {description}
//             </div> */}
//           </div>
//         </div>
//       </div>
//       <div className="font-semibold mt-2">{weight()}</div>

//       <div className="flex-row text-xs flex-wrap items-center md:gap-3 gap-1">
//         <div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-3 text-sm h-auto items-center justify-center mr-4 mt-2">
//           {prices.slice(0, 4).map((price, index) => {
//             if (index === 0 && price * netWeight >= 0.5) {
//               return (
//                 <div
//                   key={index}
//                   className="p-1 px-0 w-auto bg-orange-500 hover:bg-white hover:text-orange-500 text-center rounded-lg"
//                   onClick={handleBuyP(price)}
//                 >
//                   ₹ {price}
//                 </div>
//               );
//             } else if (index !== 0 && price * netWeight <= 1.7) {
//               return (
//                 <div
//                   key={index}
//                   className="p-1 px-0 w-auto bg-orange-500 hover:bg-white hover:text-orange-500 text-center rounded-lg"
//                   onClick={handleBuyP(price)}
//                 >
//                   ₹ {price}
//                 </div>
//               );
//             }
//             return null;
//           })}
//         </div>

//         {/* <div className="border-2 border-orange-200 rounded-full bg-orange-200 ">
//           <select
//             value={selectedPrice}
//             onChange={handlePriceChange}
//             className="drop md:text-lg text-sm rounded-full border-transparent border-white p-2 font-medium focus:outline-none bg-transparent"
//             aria-label="Select price"
//           >
//             {prices.map((price, index) => (
//               <option key={index} value={price}>
//                 ₹ {price}
//               </option>
//             ))}
//           </select>
//           <span className="pointer-events-none pr-2 -ml-2">▼</span>
//         </div> */}
//         {/* <input
//           type="number"
//           value={customPrice}
//           onChange={handleCustomPriceChange}
//           placeholder="₹ Custom"
//           className="border border-black rounded-full p-2 md:text-lg text-sm w-24"
//           aria-label="Custom price"
//         /> */}

//         {
//           <button
//             onClick={handleBuy}
//             className="buy md:text-lg text-lg bg-green-400  font-semibold py-2 md:px-2 px-3"
//             aria-label="Buy now"
//           >
//             {orderPlaced ? "Added" : "Add to Cart"}
//           </button>
//           // <div className="flex items-center gap-20">
//           //   <div className="flex items-center gap-4">
//           //     <FontAwesomeIcon
//           //       icon={faMinusCircle}
//           //       className="cursor-pointer text-2xl"
//           //       onClick={() => handleDecrease()}
//           //     />
//           //     <span className="font-semibold w-5 text-black">{quantity}</span>

//           //     <FontAwesomeIcon
//           //       icon={faPlusCircle}
//           //       className="cursor-pointer text-2xl"
//           //       onClick={() => handleIncrease()}
//           //     />
//           //   </div>
//           // </div>
//         }
//       </div>
//     </div>
//   );
// };

// export default Card;
