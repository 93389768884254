import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Cart from "./Components/Cart";
import TermsAndConditions from "./Components/Policy";
import PaymentPage from "./Components/Payment";
import LoginPage from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import ReactGA from "react-ga4";

const App = () => {
  const user = useSelector((state) => state.user.user);
  const cartItems = useSelector((state) => state.cart.items || []);

  // if (window.location.protocol !== "https:") {
  //   window.location.href =
  //     "https://" +
  //     window.location.href.substring(window.location.protocol.length);
  // }

  const location = useLocation(); // Hook to access location (current URL)

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_GCODE_IVAR}`);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    return () => {};
  }, [location]);
  const handleClick = (category, action, label, value) => {
    ReactGA.event({
      category: category || "User", // Category of the event
      action: action || "Clicked Button", // Action taken
      label: label || "Button", // Optional label to provide additional information (like the form name)
      value: value || 1,
    });
  };

  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home handleClick={handleClick} />} />
        <Route
          path="/login"
          element={<LoginPage handleClick={handleClick} />}
        />
        <Route path="/cart" element={<Cart handleClick={handleClick} />} />
        <Route
          path="/policy"
          element={<TermsAndConditions handleClick={handleClick} />}
        />
        <Route
          path="/payment"
          element={
            user && cartItems.length > 0 ? (
              <PaymentPage handleClick={handleClick} />
            ) : (
              <Navigate to="/cart" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            user ? (
              <Dashboard handleClick={handleClick} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer handleClick={handleClick} />
    </div>
  );
};

export default App;
