import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../Redux/cartActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Cart = ({handleClick}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const language = useSelector((state) => state.language.language);
  const cartItems = useSelector((state) => state.cart.items || []);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const addTotal = () => {
    let tot = 0;
    let tw = 0;
    cartItems.forEach((item) => {
      tot += item.price * item.quantity;
      tw += item.netWeight * item.price * item.quantity;
    });
    setTotal(tot);
    setTotalWeight(tw);
  };

  useEffect(() => {
    addTotal();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const calculateWeight = (netWeight, selectedPrice, quantity) => {
    let wt = netWeight * selectedPrice;
    wt = Math.round((1000 * wt) / 100) * 100;
    if (quantity !== 0) wt *= quantity;
    return wt < 1000 ? `${wt} gm` : `${(wt / 1000).toFixed(2)} kg`;
  };
  const handleIncrease = (item) => {
    setTotal(total + item.price);
    setTotalWeight(totalWeight + item.price * item.netWeight);
    dispatch(addToCart({ ...item, quantity: item.quantity + 1 }));
  };

  const handleDecrease = (item) => {
    if (item.quantity > 1) {
      setTotal(total - item.price);
      setTotalWeight(totalWeight - item.price * item.netWeight);
      dispatch(addToCart({ ...item, quantity: item.quantity - 1 }));
    } else {
      handleRemove(item);
    }
  };

  const handleRemove = (item) => {
    setTotal(total - item.price * item.quantity);
    setTotalWeight(totalWeight - item.price * item.quantity * item.netWeight);
    item.quantity = 0;
    dispatch(addToCart({ ...item, quantity: 0 }));
  };
  if (cartItems.length === 0) {
    setTimeout(() => {
      navigate("/");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  }
  const handleCheckout = () => {
    handleClick('User', 'Going to Cart', 'Cart', 1);
    if (user) {
      navigate("/payment"); // Navigate to the payment page if user is logged in
    } else {
      navigate("/login"); // Navigate to the login page if user is not logged in
    }
  };

  const weightWarning = [
    "Total weight should be less than 2 kg!",
    "कुल वजन 2 किलोग्राम से कम होना चाहिए!",
  ];
  const weightWarning2 = [`Current Weight `, `वर्तमान वजन  `];
  const formatToTwoDecimals = (num) => parseFloat(num).toFixed(2);

  return (
    <div className="">
      <div className="h-20"></div>
      <div className="min-h-[650px]  md:m-16 mt-16 ml-0">
        <h2
          className="md:text-7xl text-3xl font-bold md:ml-0 ml-3"
          style={{
            background: "radial-gradient(circle, #FDC830, #F37335)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
          }}
        >
          Cart
        </h2>
        <div className="mt-6">
          {cartItems.length === 0 ? (
            <p className="ml-3 md:text-xl text-sm  font-semibold">
              Cart is empty.
            </p>
          ) : (
            <ol className="mt-6 mx-4">
              {cartItems.map((item, index) => (
                <li
                  key={index}
                  className="grid grid-cols-2  md:grid-cols-4 gap-4 items-start border-b-2 my-2 border-gray-300 md:text-xl"
                >
                  {/* Left Top */}
                  <div className="flex items-center col-span-1 md:col-span-1 justify-start">
                    <span className="font-semibold">{index + 1}.</span>
                    <span className="font-semibold ml-2">
                      {language === 0 ? item.title : item.hTitle}
                    </span>
                  </div>

                  {/* Right Top */}
                  <div className="col-span-1 md:col-span-1 text-right">
                    <span className="font-semibold">
                      ₹{item.price * item.quantity}
                    </span>
                  </div>

                  {/* Left Bottom */}
                  <div className="flex items-center mb-2 justify-start col-span-1 md:col-span-1">
                    <span className="text-gray-600">
                      {calculateWeight(
                        item.netWeight,
                        item.price,
                        item.quantity
                      )}
                    </span>
                  </div>

                  {/* Right Bottom */}
                  <div className="col-span-1 mb-2 md:col-span-1 text-right">
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      className="cursor-pointer text-2xl"
                      onClick={() => handleDecrease(item)}
                    />
                    <span className="font-semibold w-5 mx-2">
                      {item.quantity}
                    </span>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="cursor-pointer text-2xl"
                      onClick={() => handleIncrease(item)}
                    />
                  </div>
                </li>
              ))}
            </ol>
          )}
        </div>

        <div className="">
          {cartItems.length > 0 && totalWeight <= 2.0 ? (
            <div className="flex flex-row-reverse md:justify-start justify-right mr-6 items-center mt-4">
              <button
                onClick={handleCheckout} // Use onClick to handle navigation
                className={`p-4 text-white md:text-lg text-xs rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 
              ${
                user
                  ? "bg-orange-500 hover:bg-white hover:text-orange-500 border hover:border-orange-500 hover:shadow-lg"
                  : "bg-red-500 hover:bg-white hover:text-red-500 border hover:border-red-500 hover:shadow-lg"
              }`}
              >
                {user
                  ? language === 0
                    ? "Checkout"
                    : "खरीदे"
                  : language === 0
                  ? "Login to Proceed"
                  : "जारी रखने के लिए लॉगिन करें"}
              </button>
            </div>
          ) : null}
        </div>
        <div className="h-6"></div>
        {totalWeight > 2.0 && (
          <div className=" mx-auto text-youtube">
            <div className="font-bold  md:text-xl text-wrap text-center ">
              {weightWarning[language]}
            </div>
            <div className="font-bold  md:text-xl text-wrap text-center">
              {weightWarning2[language]}: {formatToTwoDecimals(totalWeight)} Kg{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
